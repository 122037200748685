<template>
    <div class="container mb-5 mt-5">
        <OpportunitiesList template="index" />
    </div>
  </template>

<script>
import OpportunitiesList from "@/components/lists/OpportunitiesList.vue";

export default {
  components: {
    OpportunitiesList,
    
  },
};

</script>
